






























































import moment from 'moment';
import { ref, PropType, computed, defineComponent, inject } from '@vue/composition-api';
import { ObjectId } from 'bson';
import ProofPreview from './ProofPreview.vue';
import { MAKE_ADK_TABLE_HEADER } from './const';
import { TableItem } from './types';

export default defineComponent({
  name: 'TableView',

  components: {
    ProofPreview
  },
  props: {
    items: {
      required: true,
      type: Array as PropType<TableItem[]>
    },
    userId: {
      required: true,
      type: Object as PropType<ObjectId>
    },
    loading: {
      type: Boolean
    },
    viewOnly: {
      type: Boolean
    }
  },
  setup(props) {
    const readonly = inject('readonly', false);
    const filter = ref('Personal');
    const currentId = ref('');
    const tableItems = computed(() =>
      props.items
        .filter((item: TableItem) => {
          if (filter.value === 'Personal')
            return item.author.toString() === props.userId.toString();
          return true;
        })
        .map(item => {
          return { ...item, id: item.id.toString() };
        })
    );
    const formatDate = (date: Date) => {
      return moment(date).fromNow();
    };
    return {
      header: ref(MAKE_ADK_TABLE_HEADER),
      formatDate,
      filter,
      tableItems,
      readonly,
      currentId
    };
  }
});
